<template>
  <div class="page-root tools-vin-root">
    <div class="vin-page">
      <div class="title">在线评估 >  VIN码精准评估 >  <span>查询报告</span></div>
      <div class="title-sm">VIN查询报告</div>
      <div class="result-item2">
        <div class="box1">
          <div class="item">
            <div class="font1">新车价格</div>
            <div class="item2">
              <div class="font2"><span class="font3">￥</span>{{formatNum(detail["Model"] && detail["Model"]["Price"] ? detail["Model"]["Price"] : '')}}<span class="font3">万</span></div>
              <div class="font4">厂商指导价</div>
            </div>
            <div class="item2">
              <div class="font22"><span class="font33">￥</span>{{formatNum(detail["NewPrice"]["Price"])}}<span class="font33">万</span></div>
              <div class="font4">新车优惠价</div>
            </div>
          </div>
          <div class="item22">
            <div class="font1">二手车价格</div>
            <div class="item2">
              <div class="font2"><span class="font3">￥</span>{{formatNum(detail["UsedPrice"]["BuyPrice"])}}<span class="font3">万</span></div>
              <div class="font4">收车价</div>
            </div>
            <div class="item2">
              <div class="font22"><span class="font33">￥</span>{{formatNum(detail["UsedPrice"]["SellPrice"])}}<span class="font33">万</span></div>
              <div class="font4">零售价</div>
            </div>
          </div>
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 查询信息</div>
        <el-table size="small" :data="tableData" style="width: 100%; margin-top: 10px">
          <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
          <el-table-column label="品牌车系">
            <template slot-scope="scope">
              {{ scope.row.brand }} - {{ scope.row.series }}
            </template>
          </el-table-column>
          <el-table-column prop="vehicleType" label="车型名称"> </el-table-column>
          <el-table-column prop="createTime" label="查询时间"> </el-table-column>
        </el-table>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 基本信息</div>
        <div class="box">
          <div class="item">
            <div>厂商</div><div>{{detail["Mfrs"] ? detail["Mfrs"] : '--'}}</div>
          </div>
          <div class="item">
            <div>品牌</div><div>{{detail["Brand"] ? detail["Brand"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车系</div><div>{{detail["Series"] ? detail["Series"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车型</div><div>{{detail["Model"] && detail["Model"]["Name"] ? detail["Model"]["Name"] : '--'}}</div>
          </div>
          <div class="item">
            <div>年款</div><div>{{detail["Model"] && detail["Model"]["VersionYear"] ? detail["Model"]["VersionYear"] : '--'}}</div>
          </div>
          <div class="item">
            <div>上市时间</div><div>{{detail["ProductionDate"] ? detail["ProductionDate"] : '--'}}</div>
          </div>
          <div class="item">
            <div>厂商指导价（万）</div><div>{{formatNum(detail["Model"] && detail["Model"]["Price"] ? detail["Model"]["Price"] : '')}}</div>
          </div>
          <div class="item">
            <div>排量（L）</div><div>{{detail["Model"] && detail["Model"]["Displacement"] ? detail["Model"]["Displacement"] : '--'}}</div>
          </div>
          <div class="item">
            <div>进气方式</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["AirIntake"] ? detail["Detail"]["Engine"]["AirIntake"] : '--'}}</div>
          </div>
          <div class="item">
            <div>环保标准</div><div>{{detail["Detail"] && detail["Detail"]["Basic"] && detail["Detail"]["Basic"]["Emission"] ? detail["Detail"]["Basic"]["Emission"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车身结构</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["CarBodyForm"] ? detail["Detail"]["Body"]["CarBodyForm"] : '--'}}</div>
          </div>
          <div class="item">
            <div>发动机号</div><div>{{detail["EngineNo"] ? detail["EngineNo"] : '--'}}</div>
          </div>
          <div class="item">
            <div>发动机</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["EngineModel"] ? detail["Detail"]["Engine"]["EngineModel"] : '--'}}</div>
          </div>
          <div class="item">
            <div>整备重量（Kg）</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Weight"] ? detail["Detail"]["Body"]["Weight"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车门数</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Doors"] ? detail["Detail"]["Body"]["Doors"] : '--'}}</div>
          </div>
          <div class="item">
            <div>乘员数</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Seating"] ? detail["Detail"]["Body"]["Seating"] : '--'}}</div>
          </div>
          <div class="item">
            <div>驱动方式</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["DrivingMethod"] ? detail["Detail"]["Chassis"]["DrivingMethod"] : '--'}}</div>
          </div>
          <div class="item">
            <div>变速箱类型</div><div>{{detail["Model"] && detail["Model"]["TransmissionType"]? detail["Model"]["TransmissionType"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大扭矩（N  m)</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["PeakTorque"] ? detail["Detail"]["Engine"]["PeakTorque"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大功率（kW)</div><div>{{detail["Detail"] && detail["Detail"]["Driving"] && detail["Detail"]["Driving"]["MaximumPowerKW"] ? detail["Detail"]["Driving"]["MaximumPowerKW"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最高车速（Km/h)</div><div>{{detail["Detail"] && detail["Detail"]["Driving"] && detail["Detail"]["Driving"]["MaximumSpeed"] ? detail["Detail"]["Driving"]["MaximumSpeed"] : '--'}}</div>
          </div>
          <div class="item">
            <div>燃油标号</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["FuelType"] ? detail["Detail"]["Engine"]["FuelType"] : '--'}}</div>
          </div>
          <div class="item">
            <div>供油方式</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["FuelSupplyMode"] ? detail["Detail"]["Engine"]["FuelSupplyMode"] : '--'}}</div>
          </div>
          <div class="item">
            <div>长/宽/高（mm)</div>
            <div>
              {{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Length"] ? detail["Detail"]["Body"]["Length"] : '--'}}/
              {{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Width"] ? detail["Detail"]["Body"]["Width"] : '--'}}/
              {{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["Height"] ? detail["Detail"]["Body"]["Height"] : '--'}}
            </div>
          </div>
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 车身参数</div>
        <div class="box">
          <div class="item">
            <div>前轮距</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["FrontTrack"] ? detail["Detail"]["Body"]["FrontTrack"] : '--'}}</div>
          </div>
          <div class="item">
            <div>油箱容积</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["FuelTank"] ? detail["Detail"]["Body"]["FuelTank"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最小离地间距</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["MinimumGroundClearance"] ? detail["Detail"]["Body"]["MinimumGroundClearance"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后轮距</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["RearTrack"] ? detail["Detail"]["Body"]["RearTrack"] : '--'}}</div>
          </div>
          <div class="item">
            <div>轴距</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["WheelBase"] ? detail["Detail"]["Body"]["WheelBase"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车体形式</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["CarBodyForm"] ? detail["Detail"]["Body"]["CarBodyForm"] : '--'}}</div>
          </div>
          <div class="item">
            <div>风阻系数</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["DragCoefficient"] ? detail["Detail"]["Body"]["DragCoefficient"] : '--'}}</div>
          </div>
          <div class="item">
            <div>前悬长度</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["FrontSuspensionLength"] ? detail["Detail"]["Body"]["FrontSuspensionLength"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车蓬形式</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["HoodForm"] ? detail["Detail"]["Body"]["HoodForm"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车蓬开合方式</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["HoodOpening"] ? detail["Detail"]["Body"]["HoodOpening"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大总质量</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["MaximumMass"] ? detail["Detail"]["Body"]["MaximumMass"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后悬长度</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["RearSuspensionLength"] ? detail["Detail"]["Body"]["RearSuspensionLength"] : '--'}}</div>
          </div>
          <div class="item">
            <div>车顶形式</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["RoofForm"] ? detail["Detail"]["Body"]["RoofForm"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大行李箱容积</div><div>{{detail["Detail"] && detail["Detail"]["Body"] && detail["Detail"]["Body"]["MaximumTrunk"] ? detail["Detail"]["Body"]["MaximumTrunk"] : '--'}}</div>
          </div>
          <div v-for="item in 4" :key="item" style="width: 187px;"></div>
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 底盘参数</div>
        <div class="box">
          <div class="item">
            <div>驱动方式</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["DrivingMethod"] ? detail["Detail"]["Chassis"]["DrivingMethod"] : '--'}}</div>
          </div>
          <div class="item">
            <div>四驱方式</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["FWDMethod"] ? detail["Detail"]["Chassis"]["FWDMethod"] : '--'}}</div>
          </div>
          <div class="item">
            <div>前制动</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["FrontBrake"] ? detail["Detail"]["Chassis"]["FrontBrake"] : '--'}}</div>
          </div>
          <div class="item">
            <div>前悬架</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["FrontSuspension"] ? detail["Detail"]["Chassis"]["FrontSuspension"] : '--'}}</div>
          </div>
          <div class="item">
            <div>前轮胎规格</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["FrontTireSize"] ? detail["Detail"]["Chassis"]["FrontTireSize"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后轮胎规格</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["RearTireSize"] ? detail["Detail"]["Chassis"]["RearTireSize"] : '--'}}</div>
          </div>
          <div class="item">
            <div>转向助力类型</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["PowerSteering"] ? detail["Detail"]["Chassis"]["PowerSteering"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后悬架</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["RearSuspension"] ? detail["Detail"]["Chassis"]["RearSuspension"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后制动</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["RearBrake"] ? detail["Detail"]["Chassis"]["RearBrake"] : '--'}}</div>
          </div>
          <div class="item">
            <div>备胎</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["SpareTire"] ? detail["Detail"]["Chassis"]["SpareTire"] : '--'}}</div>
          </div>
          <div class="item">
            <div>前轮毂材料</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["FrontHubMaterial"] ? detail["Detail"]["Chassis"]["FrontHubMaterial"] : '--'}}</div>
          </div>
          <div class="item">
            <div>后轮毂材料</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["RearHubMaterial"] ? detail["Detail"]["Chassis"]["RearHubMaterial"] : '--'}}</div>
          </div>
          <div class="item">
            <div>变速方式</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["Transmission"] ? detail["Detail"]["Chassis"]["Transmission"] : '--'}}</div>
          </div>
          <div class="item">
            <div>排档方式</div><div>{{detail["Detail"] && detail["Detail"]["Chassis"] && detail["Detail"]["Chassis"]["GearMode"] ? detail["Detail"]["Chassis"]["GearMode"] : '--'}}</div>
          </div>
          <div v-for="item in 4" :key="item" style="width: 187px;"></div>
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 发动机参数</div>
        <div class="box">
          <div class="item">
            <div>进气方式</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["AirIntake"] ? detail["Detail"]["Engine"]["AirIntake"] : '--'}}</div>
          </div>
          <div class="item">
            <div>压缩比</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["Compression"] ? detail["Detail"]["Engine"]["Compression"] : '--'}}</div>
          </div>
          <div class="item">
            <div>缸体材料</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["CylinderBlock"] ? detail["Detail"]["Engine"]["CylinderBlock"] : '--'}}</div>
          </div>
          <div class="item">
            <div>缸盖材料</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["CylinderCover"] ? detail["Detail"]["Engine"]["CylinderCover"] : '--'}}</div>
          </div>
          <div class="item">
            <div>缸径</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["CylinderDiameter"] ? detail["Detail"]["Engine"]["CylinderDiameter"] : '--'}}</div>
          </div>
          <div class="item">
            <div>气缸数</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["CylinderNum"] ? detail["Detail"]["Engine"]["CylinderNum"] : '--'}}</div>
          </div>
          <div class="item">
            <div>发动机型号</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["EngineModel"] ? detail["Detail"]["Engine"]["EngineModel"] : '--'}}</div>
          </div>
          <div class="item">
            <div>气缸排列形式</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["EngineType"] ? detail["Detail"]["Engine"]["EngineType"] : '--'}}</div>
          </div>
          <div class="item">
            <div>排气量</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["Exhaust"] ? detail["Detail"]["Engine"]["Exhaust"] : '--'}}</div>
          </div>
          <div class="item">
            <div>燃油供给方式</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["FuelSupplyMode"] ? detail["Detail"]["Engine"]["FuelSupplyMode"] : '--'}}</div>
          </div>
          <div class="item">
            <div>燃料类型标号</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["FuelType"] ? detail["Detail"]["Engine"]["FuelType"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大功率</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["MaximumPower"] ? detail["Detail"]["Engine"]["MaximumPower"] : '--'}}</div>
          </div>
          <div class="item">
            <div>最大扭矩</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["PeakTorque"] ? detail["Detail"]["Engine"]["PeakTorque"] : '--'}}</div>
          </div>
          <div class="item">
            <div>每缸气门数</div><div>{{detail["Detail"] && detail["Detail"]["Engine"] && detail["Detail"]["Engine"]["ValveNum"] ? detail["Detail"]["Engine"]["ValveNum"] : '--'}}</div>
          </div>
          <div v-for="item in 4" :key="item" style="width: 187px;"></div>
        </div>
      </div>
      <div class="result-item3">
        <div class="title"><span></span> 我的查询记录</div>
        <el-table size="small" :data="tableLocalData" style="width: 100%; margin-top: 10px">
          <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
          <el-table-column prop="vin" label="车辆VIN码"> </el-table-column>
          <el-table-column label="品牌车系">
            <template slot-scope="scope">
              {{ scope.row.brand }} - {{ scope.row.series }}
            </template>
          </el-table-column>
          <el-table-column prop="vehicleType" label="车辆名称"> </el-table-column>
          <el-table-column prop="createTime" label="查询时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text"
                ><a href="#tools-pgResult-root">查看详情</a></el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { init } from 'echarts';
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get } from '@/utils/axios';
export default {
  name: 'VinResult',
  mounted() {
    this.tableData.push(this.$route.query)
    this.detail = JSON.parse(this.$route.query.jsonResult)
    this.init();
  },
  data() {
    return {
      tableData: [],
      tableLocalData: JSON.parse(localStorage['Tools-vinResult'] || '[]').slice(-5),
      list: {},
      detail: ""
    };
  },
  methods: {
    init(){
      if(this.tableData[0].id == 1){
        return
      }
      if (localStorage['Tools-vinResult']) {
        const pgResult = JSON.parse(localStorage['Tools-vinResult']);
        if (!pgResult.map((v) => v.id).includes(this.tableData[0].id)) {
          localStorage['Tools-vinResult'] = JSON.stringify([...pgResult, this.tableData[0]]);
        }
      } else {
        localStorage['Tools-vinResult'] = JSON.stringify([this.tableData[0]]);
      }
    },
    handleClick(row){
      get(
        `/system/vin/selectById?id=${row.id}`
      ).then((res) => {
        if (res.code == 200) {
          let data = res.data
          let query = {
            id: data.id,
            vin: data.vin,
            orderNo: data.orderNo,
            brand: data.brand,
            series: data.series,
            vehicleType: data.vehicleType,
            createTime: data.createTime,
            jsonResult: data.jsonResult
          }
          this.tableData = []
          this.tableData.push(query)
          this.detail = JSON.parse(query.jsonResult)
          console.log(this.detail)
          this.init();
          this.scrollTop();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    scrollTop() {
      document.getElementById('app').scrollTo(0, 0);
    },
    formatNum(num) {
      return num ? parseFloat(num).toFixed(2) : '';
    },
  }
};
</script>
